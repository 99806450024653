<template>
  <div
    class="epicentral-material"
    :style="`background-color: ${item.color}`"
    v-if="item"
    @click="$emit('toMaterial', item.slug)"
  >
    <img
      class="epicentral-material__background"
      v-if="item.text_preview"
      :src="item.text_preview"
    />

    <div
      class="epicentral-material__content"
      :class="{
        'epicentral-material__text': item.material_type === 'Статья',
        'epicentral-material__video': item.material_type === 'Видео',
      }"
    >
      <div class="epicentral-material__type">
        <div class="epicentral-material__type-icon">
          <svg
            v-if="item.material_type === 'Видео'"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="21.5" stroke="currentColor" />
            <rect
              x="9.5"
              y="13.5"
              width="26"
              height="18"
              rx="0.5"
              stroke="currentColor"
            />
            <path
              d="M20 25V20C20 19.176 20.9408 18.7056 21.6 19.2L24.9333 21.7C25.4667 22.1 25.4667 22.9 24.9333 23.3L21.6 25.8C20.9408 26.2944 20 25.824 20 25Z"
              stroke="currentColor"
            />
          </svg>
          <svg
            v-else
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="22" cy="22" r="21.5" stroke="currentColor" />
            <path d="M37 37.5V10H8L8.5 38.5" stroke="currentColor" />
            <path d="M29 17V10H30L36.5 16V17H29Z" stroke="currentColor" />
            <path d="M12 24H33" stroke="currentColor" />
            <path d="M12 28H27.1111H28" stroke="currentColor" />
            <path d="M12 32H29.9444H31" stroke="currentColor" />
          </svg>
        </div>
        <div class="epicentral-material__type-text">
          <span>{{ item.material_type }}</span>
        </div>
      </div>
      <div class="epicentral-material__image-container">
        <img class="epicentral-material__image" :src="item.text_preview" />
      </div>
      <div class="epicentral-material__text-container">
        <div class="epicentral-material__title" v-html="item.title"></div>
        <div
          class="epicentral-material__speaker"
          v-if="item.material_type === 'Видео'"
          v-html="item.speaker"
        ></div>
        <div
          class="epicentral-material__description"
          v-if="item.material_type === 'Видео'"
          v-html="item.short_description"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpicentralMaterial",
  props: {
    item: Object,
  },
};
</script>

<style lang="scss" scoped>
.epicentral-material {
  padding: 16px;
  border: 1px solid #d2d2d2;
  position: relative;
  cursor: pointer;
  min-height: 436px;
  transition: .3s;

  &:hover {
    transform: translateY(-10px);
  }

  @media screen and (max-width: 1220px) {
    min-height: 416px;
  }

  @media screen and (max-width: 767px) {
    min-height: 300px;
  }
// 9n + 1
  &:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0;

    @media screen and (max-width: 767px) {
      grid-column-start: auto;
      grid-column-end: auto;
    }

    .epicentral-material {
      &__type {
        position: absolute;
        top: 16px;
        left: 16px;
        color: #fff;

        &-text {
          color: #fff;
        }
      }

      &__text-container {
        padding: 16px;
        margin-top: 0;
      }

      &__title {
        color: #1f1f1f !important;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &__speaker {
        margin-top: 16px;
        margin-bottom: 16px;
        color: #1f1f1f;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      &__description {
        color: #1f1f1f;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &__background {
        display: none;
      }

      &__image-container {
        display: block;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }

  // &:nth-child(18n + 1) {
  //   grid-column-start: 1;
  //   grid-column-end: 3;

  //   @media screen and (max-width: 767px) {
  //     grid-column-start: auto;
  //     grid-column-end: auto;
  //   }
  // }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image-container {
    display: none;
  }

  &__type {
    color: #8a7098;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-text {
      margin-left: 15px;
      color: #8a7098;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
    }
  }

  &__image-container {
    width: 100%;
    height: 208px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    & + .epicentral-material__content {
      color: #fff;

      .epicentral-material__type-text {
        color: #fff;
      }

      .epicentral-material__type {
        color: #fff;
      }

      .epicentral-material__title {
        color: #fff;
      }
    }
  }

  &__text {
    .epicentral-material {
      &__text-container {
        margin-top: auto;
      }

      &__title {
        color: #1f1f1f;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 29px;
      }
    }
  }

  &__video {
    .epicentral-material {
      &__text-container {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      &__title {
        margin-top: 16px;
        margin-bottom: 16px;
        color: #590a95;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &__speaker {
        color: #3c4242;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }

      &__description {
        margin-top: auto;
        color: #3c4242;
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
</style>